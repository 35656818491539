import React, { useEffect, useState } from "react";
import "../../styles/Profile/ProfileRightBar.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURl } from "Utils/BaseURL";
import { updateInfoApi } from "Apis/MainApis";
import { fetchUser } from "../../redux/slice/fetchUserSlice";

const ProfileRightBar = () => {
  const [profileName, setProfileName] = useState("");
  const [profileNumber, setProfileNumber] = useState("");

  const dispatch = useDispatch();

  const Edituser = useSelector((state) => state?.user?.data);

  useEffect(() => {
    setProfileName(Edituser?.name);
    setProfileNumber(Edituser?.phone);
  }, [Edituser?.name, Edituser?.phone]);

  const updateInfo = async (e) => {
    e.preventDefault();
    if (profileName === "") {
      toast.error("Name field is required");
      return;
    }
    console.log(profileNumber);
    if (profileNumber === "" || profileNumber === null) {
      toast.error("Phone Number is required");
      return;
    }
    if (profileNumber.length !== 12) {
      toast.error("Phone number length should be 12 digits");
      return;
    }
    const data = {
      name: profileName,
      phone: profileNumber,
    };
    const response = await updateInfoApi(data);
    if (response?.status === 200) {
      toast("Profile Updated");
      dispatch(fetchUser());
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="ProfileForm">
          <div className="card">
            <div className="d-flex justify-content-center align-items-center pb-3">
              <div className="d-flex justify-content-center align-items-center  avtar "></div>
            </div>
            <h6>Edit Profile</h6>
            <form onSubmit={updateInfo}>
              <label for="name">Name</label>
              <input
                type="name"
                name="name"
                placeholder="Enter your name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
              <label for="password">Mobile Number</label>
              <div className="pass_visible">
                <input
                  type="number"
                  name="password"
                  placeholder="Enter your Number"
                  value={profileNumber}
                  onChange={(e) => setProfileNumber(e.target.value)}
                />
              </div>
              <label for="email">Email Address*</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={Edituser?.email}
                disabled
              />
              <button type="submit" className="login_btn">
                Save Details
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileRightBar;
