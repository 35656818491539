import FilterProduct from "Sections/CategoriesSections/FilterProduct";
import Button from "react-bootstrap/Button";
import "styles/DetailPage/ImageSlider.css";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { IoIosSend } from "react-icons/io";
import { useState } from "react";
import QuoteModel from "../Detailpage/QuoteModel";
import {
  addToWishlist,
  fetchWishlist,
  removeFromWishlist,
} from "../../redux/slice/fetchWishlistSlice";
import { useSelector, useDispatch } from "react-redux";
import LoginModal from "../../Authorization/LoginModal";
import parse from "html-react-parser";

const ImageSlider = ({
  imageSlider,
  singleProduct,
  sliderHandler,
  handleSubmit,
  productId = { productId },
  manufacturerEmail = { manufacturerEmail },
  setWishlistChange = { setWishlistChange },
  wishlist = { wishlist },
  handleCompare,
  setOpen
}) => {
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state?.user?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [QuoteM, setQuoteM] = useState(false);

  const handleWishlist = async (id, wish) => {
    if (user?.id) {
      if (wish === "yes") {
        await dispatch(removeFromWishlist(id));
      } else {
        await dispatch(addToWishlist(id));
      }
      await dispatch(fetchWishlist());
      setWishlistChange(!wishlist);
    } else {
      setShowModal(true);
    }
  };

  const handleQuoteModal = () => {
    if (user?.id) {
      setQuoteM(true);
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <QuoteModel
        QuoteM={QuoteM}
        setQuoteM={setQuoteM}
        singleProduct={singleProduct}
        productId={productId}
        manufacturerEmail={manufacturerEmail}
      />
      <LoginModal showModal={showModal} setShowModal={setShowModal} />
      <div className="ImageSlider">
        <div className="row">
          <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12">
            <div className="row mainBox_slider">
              <div className="col-md-5 col-sm-5 p-0">
                <div className="customSlider">
                  <div className="image_sec">
                    <img src={imageSlider} alt="img" className="w-100" />
                    {singleProduct.wishlist === "no" ? (
                      <>
                        <FaRegHeart
                          className="ImageSlider_heart"
                          onClick={() =>
                            handleWishlist(
                              singleProduct?.id,
                              singleProduct?.wishlist
                            )
                          }
                        />
                      </>
                    ) : (
                      <>
                        <FaHeart
                          className="ImageSlider_heart"
                          onClick={() =>
                            handleWishlist(
                              singleProduct?.id,
                              singleProduct?.wishlist
                            )
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="row d-flex flex-wrap pt-3 ">
                    {singleProduct?.imagesGet?.map((data, i) => {
                      return (
                        <div className="col-md-3 col-3 p-1" key={i}>
                          <div className="ImageSlider-small_images">
                            <img
                              src={data}
                              height="70"
                              onClick={() => sliderHandler(data)}
                              alt="img"
                              className="w-100"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="d-flex justify-content-start gap-4 mt-5">
                  <Button
                    className="global_button_colored gap-3"
                    onClick={() => handleCompare(singleProduct)}
                  >
                    <RiArrowLeftRightFill /> Compare
                  </Button>

                  {/* <Button
                    className="global_button_colored gap-3"
                    onClick={() => handleQuoteModal()}
                  >
                    <IoIosSend /> Request a Quote
                  </Button> */}
                </div>
              </div>
              <div className="col-md-7 col-sm-7 mt-sm-0 mt-4">
                <div>
                  <h3 className="text-start">{singleProduct.name}</h3>

                  <p className="title">{singleProduct.description}</p>

                  {/* <p
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: singleProduct.long_description,
                    }}
                  ></p> */}

                  {/* <p className="title">
                    {singleProduct?.long_description &&
                      parse(String(singleProduct.long_description))}
                  </p> */}

                  {/* {singleProduct?.product_file === "" ? (
                    ""
                  ) : (
                    <p className="title">
                      <iframe
                        style={{ height: "500px", width: "100%" }}
                        src={singleProduct?.product_file}
                        title="Product Description"
                      ></iframe>
                    </p>
                  )} */}

                  {/* <div className="d-flex justify-content-start gap-4">
                    <Button
                      className="global_button_colored gap-3"
                      onClick={() => handleCompare(singleProduct)}
                    >
                      <RiArrowLeftRightFill /> Compare
                    </Button>

                    <Button
                      className="global_button_colored gap-3"
                      onClick={() => handleQuoteModal()}
                    >
                      <IoIosSend /> Request a Quote
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
