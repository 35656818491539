import React, { useState, useEffect } from "react";
import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import "../../styles/AbouUs.css";
import { fetchAboutUs } from "Apis/MainApis";
import { FaAngleRight } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";

const AboutUs = () => {
  const [terms, setTerms] = useState([]);
  const [termsHeading, setTermsHeading] = useState("");

  useEffect(() => {
    async function fetchterms() {
      const response = await fetchAboutUs();
      console.log(
        "response from aboutUs",
        response?.data?.data?.about_us_data[0]?.about_file
      );
      setTerms(response?.data?.data?.about_us_data[0]?.about_file);
      // setTerms(response?.data?.data?.about_us_data[0]?.about_desc);
      setTermsHeading(response?.data?.data?.about_us_data[0]?.title);
    }
    fetchterms();
  }, []);
  return (
    <>
      <NavBar />
      <div className="global_routing_Navbar_bottom">
        <p className="d-flex align-items-center m-0">
          <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
          <span>About Us</span>
        </p>
      </div>
      <div className="AboutUs bg-body mt-2">
        <div className="AboutHeader d-flex justify-content-center align-items-center">
          <div className="aboutheader_content ">
            <h1 className="fw-bold text-white text-center">About Us</h1>
            {/* <p className="fw-bold opacity-75 text-white">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
            </p> */}
          </div>
        </div>
        {/* About us section two ------------------------------------- */}
        <div className="SectionTwoboutUs">
          <div className="row">
            {/* <div className="col-lg-6">
              <img src={sectionTwoAboutusImg} alt="img" />
            </div> */}
            <div className="col-12 mt-lg-0 mt-3">
              <div className="text-start about_description">
                {/* <h6>{termsHeading}</h6> */}
                {/* <h2 className="text-dark fw-bold py-1">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </h2> */}
                {/* <div dangerouslySetInnerHTML={{ __html: terms }}></div> */}
                {/* <Button>Read More</Button> */}
                <iframe
                  className="aboutus_iframe"
                  style={{ width: "100%" }}
                  src={terms}
                  title="Product Description"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* End ----------------------------------- */}

        {/* Our Mission ---------------------------------------------------- */}
        {/* <div className="OurMisson_sec">
          <h3 className="white  text-start fw-bold ">Our Mission and vision</h3>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="serviceBox">
                <div className="service-icon">
                  <span>
                    <FaMountain />
                  </span>
                </div>
                <h3 className="title">Mission</h3>
                <p className="description">
                  Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                </p>
                <div className="OurMisson_secBorder d-flex justify-content-center py-2">
                  <p className="m-0"></p>
                </div>
                <h4 className="fw-bold opacity-75 m-0">01</h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="serviceBox red">
                <div className="service-icon">
                  <span>
                    <MdWindPower />
                  </span>
                </div>
                <h3 className="title">Vision</h3>
                <p className="description">
                  Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                </p>
                <div className="OurMisson_secBorder d-flex justify-content-center py-2">
                  <p className="m-0"></p>
                </div>
                <h4 className="fw-bold opacity-75 m-0">02</h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="serviceBox blue">
                <div className="service-icon">
                  <span>
                    <LiaHandHoldingHeartSolid />
                  </span>
                </div>
                <h3 className="title">Value</h3>
                <p className="description">
                  Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                </p>
                <div className="OurMisson_secBorder d-flex justify-content-center py-2">
                  <p className="m-0"></p>
                </div>
                <h4 className="fw-bold opacity-75 m-0">03</h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="serviceBox green">
                <div className="service-icon">
                  <span>
                    <TbTargetArrow />
                  </span>
                </div>
                <h3 className="title">Goals</h3>
                <p className="description">
                  Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                </p>
                <div className="OurMisson_secBorder d-flex justify-content-center py-2">
                  <p className="m-0"></p>
                </div>
                <h4 className="fw-bold opacity-75 m-0">04</h4>
              </div>
            </div>
          </div>
        </div> */}
        {/* End------------------------------------------*/}

        {/* Why Choose us section ----------------------------------------- */}
        {/* <div className="ChooseUs_sec ">
          <h3 className="fw-bold ">Why Choose Us ?</h3>
          <div className="row">
            <div className="col-md-12">
              <div className="main-timeline">
                <a href="#" className="timeline">
                  <div className="timeline-icon firstTimelineSec d-flex align-items-center justify-content-center">
                    <GiStumpRegrowth className="iconOne" />
                  </div>
                  <div className="timeline-content">
                    <h3 className="title">Business Growth</h3>
                    <p className="description">
                      Everyone wants to live on top of the mountain, but all the
                      happiness and growth occurs while you're climbing it
                    </p>
                  </div>
                </a>
                <a href="#" className="timeline">
                  <div className="timeline-icon d-flex align-items-center justify-content-center">
                    <FaConnectdevelop className="icontwo" />
                  </div>
                  <div className="timeline-content">
                    <h3 className="title">New Technology</h3>
                    <p className="description">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Cupiditate ducimus officiis quod! Aperiam eveniet nam
                      nostrum odit quasi ullam voluptatum.
                    </p>
                  </div>
                </a>
                <a href="#" className="timeline">
                  <div className="timeline-icon d-flex align-items-center justify-content-center">
                    <MdOutlineCompare className="iconthree" />
                  </div>
                  <div className="timeline-content">
                    <h3 className="title">Compare</h3>
                    <p className="description">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Cupiditate ducimus officiis quod! Aperiam eveniet nam
                      nostrum odit quasi ullam voluptatum.
                    </p>
                  </div>
                </a>
                <a href="#" className="timeline">
                  <div className="timeline-icon d-flex align-items-center justify-content-center">
                    <FaCheck className="iconfour" />
                  </div>
                  <div className="timeline-content">
                    <h3 className="title">Customized solutions</h3>
                    <p className="description">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Cupiditate ducimus officiis quod! Aperiam eveniet nam
                      nostrum odit quasi ullam voluptatum.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* End ------------------------------------------------------------ */}

        {/* <div className="Productcard">
          <div className="row d-flex justify-content-around  align-items-center">
            <div className="col-lg-3 col-md-3 p-0 ">
              <div className="hex">
                <img src={productImg} alt="img" />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12  px-md-3 px-0 mt-md-0 mt-3">
              <div className="card-block px-3">
                <div className="d-flex align-items-center  gap-lg-5  gap-sm-2 gap-1 flex-wrap ">
                  <h4 className="card-title pb-2 m-0 fw-bold text-dark">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <div className="d-flex justify-content-around align-items-center gap-3">
                    <div className="hexicon">
                      <FaFacebookF className="media" />
                    </div>
                    <div className="hexicon">
                      <FaLinkedinIn className="media" />
                    </div>
                    <div className="hexicon">
                      <FaInstagram className="media" />
                    </div>
                  </div>
                </div>
                <h6 className="fw-bold mt-sm-0 mt-2">Weather Radar</h6>
                <p className="card-text text-dark">
                  Consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur.
                </p>

                <a href="#" className="btn">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="Productcard">
          <div className="row  d-flex justify-content-around  align-items-center">
            <div className="col-lg-8 col-md-8 col-sm-12  px-md-3 px-0">
              <div className="card-block px-3">
                <div className="d-flex align-items-center  gap-lg-5  gap-sm-2 gap-1 flex-wrap">
                  <h4 className="card-title pb-2 m-0 fw-bold text-dark">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <div className="d-flex justify-content-around align-items-center gap-3">
                    <div className="hexicon">
                      <FaFacebookF className="media" />
                    </div>
                    <div className="hexicon">
                      <FaLinkedinIn className="media" />
                    </div>
                    <div className="hexicon">
                      <FaInstagram className="media" />
                    </div>
                  </div>
                </div>
                <h6 className="fw-bold mt-sm-0 mt-2">Wind Radar</h6>
                <p className="card-text text-dark">
                  Consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur.
                </p>
                <a href="#" className="btn ">
                  Read More
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 p-0 mt-md-0 mt-3">
              <div className="hex">
                <img src={productImgTwo} alt="img" />
              </div>
            </div>
          </div>
        </div> */}

        {/* About Contact us _----------------------------------------- */}
        {/* <div className="contact-cards ">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h3 className="fw-bold mb-3">Contact Us</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 ">
              <div className="card">
                <span className="icon">
                  <FiPhoneCall className="i" />
                </span>
                <div className="content-wrap">
                  <span className="item-title">Call us anytime</span>
                  <p className="text">+386 5555 235 / +386 8754 231</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-md-0 mt-3">
              <div className="card">
                <span className="icon">
                  <FaBuilding className="i" />
                </span>
                <div className="content-wrap">
                  <span className="item-title">Our headquarters </span>
                  <p className="text">
                    300 Pennsylvania Ave NW, Washington, DC 20006, USA
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-md-3 mt-3">
              <div className="card">
                <span className="icon">
                  <MdOutlineEmail className="i" />
                </span>
                <div className="content-wrap">
                  <span className="item-title">Call us anytime</span>
                  <p className="text">+386 5555 235 / +386 8754 231</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* end------------------------------------------------- */}
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
